$body-bg: #edf2f9;

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&family=Open+Sans:wght@300&display=swap");

*{
  font-family: "Open Sans", sans-serif;
  // font-family: "Bebas Neue", cursive;
}

$primary-color: #5f27cd;

.btn-primary {
  @include button-variant(
    $primary-color,
    darken($primary-color, 7.5%),
    darken($primary-color, 10%),
    lighten($primary-color, 5%),
    lighten($primary-color, 10%),
    darken($primary-color, 30%)
  );
}

.btn-outline-primary {
  @include button-outline-variant(
    $primary-color,
    #fff,
    lighten($primary-color, 5%),
    $primary-color
  );
}

:disabled{
    cursor: not-allowed;
}


.site-layout .site-layout-header {
  background: #fff;
}

.site-layout-background{
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
}

.ant-layout-sider{
  height: 100vh;
}

.ant-layout-sider-children{
  background-color: #fff;
}
.menu-link:hover{
  text-decoration: none;
}

.info-number{
  font-size: 3rem;
}

.info-heading{
  font-family: "Bebas Neue", cursive;
}

.text-primary{
  color: $primary-color !important;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #e74c3c;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #c0392b;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.uppy-Dashboard-isFixed{
overflow: auto !important;
}


.loader{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.word-wrap { word-wrap: break-word; }

.ant-image-placeholder{
  position: relative;
}

.ant-image-placeholder>.ant-image{
  width: 100%;
}